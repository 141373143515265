<!--  -->
<template>
  <div class="space-list-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '知识库',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <van-list v-if="menuList.length > 0">
        <van-cell
          class="item"
          v-for="(menu, i) in menuList"
          :key="i"
          :is-link="menu.spaceType == 1"
          @click="handleItem(menu, i)"
        >
          <p class="left">
            <van-image
              width=".4rem"
              height=".4rem"
              fit="cover"
              :src="require('@/assets/images/setting/phone.png')"
            />
            <span class="title">{{ menu.fileName }}</span>
          </p>
        </van-cell>
      </van-list>

      <van-empty v-else />
    </div>
    <van-action-sheet
      description="您所在的部门"
      v-model="showActions"
      :actions="deparmentList"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      showActions: false,
      activeMenu: "",
    };
  },

  components: { TopWrapper},

  computed: {
    ...mapState("fileManagerNew", ["menuList", "deparmentList"]),
  },
  created() {
    this.getDepartments();
  },
  mounted() {},

  methods: {
    ...mapMutations("fileManagerNew", ["setDepartmentList"]),
    ...mapActions("fileManagerNew", ["getDepartments"]),
    handleItem(item, i) {
      if (item.spaceType == "1") {
        this.showActions = true;
        return;
      }
      this.$router.push({
        path: "/fileFolderNew",
        query: {
          ...item,
          activeMenu: item.fileName,
        },
      });
    },
    onSelect(item) {
      this.showActions = false;
      this.$router.push({
        path: "/fileFolderNew",
        query: {
          ...item,
          activeMenu: item.fileName,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.space-list-page {
  width: 100%;
  height: 100%;
  min-height: 6rem;
  background: #fff;
  position: relative;
  .main-wrapper {
    margin-top: 0.01rem;
    height: 100%;
    ::v-deep .van-cell {
      height: 1.3rem;
      line-height: 1.3rem;
      .van-cell__value--alone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 0.8rem;
      }
    }
    .left {
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        margin-left: 0.28rem;
        font-size: 0.3rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.42rem;
      }
    }
  }
}
</style>
